import { Box, Grid } from "@mui/material";
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import Logo from 'src/components/logo';
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { RouterLink } from "src/routes/components";
import Button from "@mui/material/Button";
// ----------------------------------------------------------------------

const LINKS = [
  // {
  //   headline: 'Minimal',
  //   children: [
  //     { name: 'About us', href: paths.root },
  //     { name: 'Contact us', href: paths.root },
  //     { name: 'FAQs', href: paths.root },
  //   ],
  // },
  {
    headline: 'Policy & Terms',
    children: [
      { name: 'Terms and Condition', href: 'terms-and-conditions.html' },
      { name: 'Privacy Policy', href: "privacy-policy.html" },
    ],
  },
  // {
  //   headline: 'Contact',
  //   children: [{ name: 'hello@Voolist.com', href: 'mailto:hello@Voolist.com' }],
  // },
];

// ----------------------------------------------------------------------

export default function Footer () {
  const mdUp = useResponsive('up', 'md');
  const handleExternalLinkClick = (href) => {
    window.location.href = href;
  };

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 4,
          pb: 4,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        {
          mdUp && <Logo sx={{ mb: 3 }} />
        }


        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'row',
          alignContent: "center",

        }}>

          <Box
            sx={{
              flex: 0.5,
            }}
          >

            <Typography
              variant="body2"
            >
              Voolist is the best  cross listing tool for resellers.
              With Sales detector, bulk delist/list and more, Voolist is the all you need.
            </Typography>
            <Typography variant="body2" sx={{ width: "80%", mt: 2 }}>
              The term &apos;Etsy&apos; is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
            </Typography>
          </Box>

          {/* <Box sx={{ flex: 1, ml: 7 }}>
          </Box> */}
          <Grid xs={12} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => {
                    if (link.name === "Terms and Condition" || link.name === "Privacy Policy") {
                      <Button
                        key={link.name}
                        onClick={() => handleExternalLinkClick(link.href)}
                        color="inherit"
                        variant="text"
                        sx={{ textTransform: "none" }}
                      >
                        {link.name}
                      </Button>
                    }

                    return (
                      <Link
                        key={link.name}
                        component={RouterLink}
                        to={link.href}
                        target='_blank'
                        color="inherit"
                        variant="body2"
                      >
                        {link.name}
                      </Link>
                    )
                  })}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Box>


        <Typography variant="body2" sx={{ textAlign: "center", mt: 5 }}>
          © 2024. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
}
